<template>
  <CustomDialog
    v-model="open"
    :title="isEdit ? 'Update Configuration' : 'New Configuration'"
    @button2="save"
    :main-btn-disabled="!valid"
    :button2-text="isEdit ? 'Update' : 'Save'"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <div class="mb-2">
              <label class="subtitle-2 primary--text"> Name (Required) </label>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model="name"
                :rules="[requiredRule()]"
              >
              </v-text-field>
            </div>
            <div class="mb-2">
              <label class="subtitle-2 primary--text">
                Twilio Account SID (Required)
              </label>
              <v-text-field
                outlined
                dense
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="account_sid"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <label class="subtitle-2 primary--text">
                Twilio Auth Token (Required)
              </label>
              <v-text-field
                outlined
                dense
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="token"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <label class="subtitle-2 primary--text">
                Twilio Number (Required in e164 format e.g. +639954884743)
              </label>
              <v-text-field
                outlined
                dense
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="source_number"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <label class="subtitle-2 primary--text">
                Twilio Message Service ID (Optional)
              </label>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model="message_sid"
              ></v-text-field>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  components: { CustomDialog },
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val
        if (val && !this.isEdit) {
          this.resetValidation()
          this.name = null
          this.account_sid = null
          this.token = null
          this.message_sid = null
          this.source_number = null
        }
      },
      immediate: true
    },
    open: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    item: {
      handler: function (val) {
        this.name = !val ? null : val.friendly_name
        this.account_sid = !val ? null : val.account_sid
        this.token = !val ? null : val.mask_token
        this.message_sid = !val ? null : val.message_sid
        this.source_number = !val ? null : val.source_number
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    isEdit() {
      return this.item ? true : false
    },
    payload() {
      return {
        id: this.isEdit ? this.item.id : null,
        name: this.name,
        account_sid: this.account_sid,
        token: this.token,
        source_number: this.source_number,
        message_sid: this.message_sid
      }
    }
  },
  data: () => ({
    valid: false,
    open: false,
    name: null,
    account_sid: null,
    token: null,
    source_number: null,
    message_sid: null
  }),
  methods: {
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    }
  }
}
</script>
