<template>
  <v-card flat outlined class="rounded-10 campaign-settings" min-height="100vh">
    <v-card-title
      class="primary--text d-flex align-center justify-space-between fw-700"
    >
      <span class="ml-2">SMS/MMS Settings</span>
      <v-btn @click="goBack" icon>
        <v-icon large color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!showGuide">
      <v-sheet class="secondary--text body pa-5">
        In order to create SMS/MMS campaigns. You need first to setup your
        Twilio account integration.<br />
        <v-btn
          color="primary"
          @click="showGuide = true"
          small
          depressed
          class="my-2 text-none"
        >
          Twilio Guides
        </v-btn>
      </v-sheet>
      <v-row dense align="stretch" v-if="!loading_items">
        <v-col
          cols="12"
          v-for="each in items"
          :key="each.id"
          md="6"
          sm="12"
          class="pa-2"
        >
          <EachTwilio
            :item="each"
            @delete="handleDelete(each)"
            @edit="action_add_edit_dialog(true, each)"
          ></EachTwilio>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="pa-2">
          <v-card
            class="rounded-10 d-flex align-center justify-center fill-height"
            elevation="5"
            min-height="400"
            @click="action_add_edit_dialog(true, null)"
          >
            <div class="d-flex flex-column pa-5 align-center justify-center">
              <v-icon color="primary" size="100">mdi-plus</v-icon>
              <span class="fw-900 fs-16 primary--text">Add New</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <PageLoader min-height="80vh" v-if="loading_items"></PageLoader>
    </v-card-text>
    <v-card-text v-else>
      <v-btn class="text-none mb-2" @click="showGuide = false" color="primary">
        <v-icon left>mdi-chevron-left</v-icon> Back
      </v-btn>
      <v-sheet>
        <strong class="primary--text">
          Step 1: Login to your Twilio account. Signup if you dont have one yet.
        </strong>
        <div class="d-flex align-center justify-start py-2">
          <v-btn
            color="primary"
            small
            depressed
            target="_blank"
            class="text-none ma-1"
            href="https://www.twilio.com/login/"
          >
            Login to Twilio
          </v-btn>
          <v-btn
            color="primary"
            small
            depressed
            class="text-none ma-1"
            target="_blank"
            href="https://www.twilio.com/try-twilio"
          >
            Signup in Twilio
          </v-btn>
        </div>
      </v-sheet>
      <v-sheet>
        <strong class="primary--text">
          Step 2: Create your project in Twilio and get the required credentials
          generated along with it.
        </strong>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/account_sid-and-token.gif')"
        ></v-img>
      </v-sheet>
      <v-sheet>
        <strong class="primary--text">
          Step 3: Purchased your number (Skip this if you had already one).
        </strong>
        <v-alert dense class="col-md-6 my-1" type="error">
          Important: Number should have capabilities for SMS and MMS
        </v-alert>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/buy-a-number.png')"
        ></v-img>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/copy-phone-number.png')"
        ></v-img>
      </v-sheet>
      <v-sheet>
        <strong class="primary--text">
          Step 4: Create a Messaging Service using the number you
          purchased/acquired. (Optional)
        </strong>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/message_service_1.png')"
        ></v-img>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/message_service_2.png')"
        ></v-img>
      </v-sheet>
      <v-sheet>
        <strong class="primary--text">
          Step 5: Add funds to your account or just enable auto recharge for
          campaigns use.
        </strong>
        <v-img
          contain
          class="my-1 ml-md-10 ml-sm-auto"
          max-width="500"
          :src="require('@/assets/tutorials/twilio/add_funds.png')"
        ></v-img>
      </v-sheet>
      <v-btn class="text-none mb-2" @click="showGuide = false" color="primary">
        <v-icon left>mdi-chevron-left</v-icon> Back
      </v-btn>
    </v-card-text>
    <TwilioConfigModal
      v-model="add_edit_dialog"
      :item="activeItem"
      @save="handleSave"
      @update="handleUpdate"
    ></TwilioConfigModal>
  </v-card>
</template>

<script>
import EachTwilio from '../components/EachTwilio.vue'
import TwilioConfigModal from '../components/TwilioConfigModal.vue'
import request from '@/services/axios_instance'
import { fetch_services } from '@/services/fetch/fetch_services'

export default {
  components: { EachTwilio, TwilioConfigModal },
  name: 'SetupTwilio',
  mixins: [fetch_services],
  data: () => ({ showGuide: false }),
  created() {
    this.showGuide = false
    this.loadItems(`api/twilio/our-numbers`, false, null, null)
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'campaigns', params: { type: 'sms' } })
    },
    handleDelete(item) {
      this.appConfirmation(
        'Delete this configuration?',
        () => {
          this.deleteItem(
            `api/twilio/our-numbers/${item.id}`,
            'Configuration deleted'
          )
        },
        null,
        'Yes'
      )
    },
    handleUpdate(payload) {
      this.updateItem(
        `api/twilio/our-numbers/${this.activeItem.id}`,
        payload,
        'Configuration updated.',
        (data) => {
          this.action_add_edit_dialog(false, data)
        }
      )
    },
    handleSave(payload) {
      this.addItem(
        `api/twilio/our-numbers`,
        payload,
        'Configuration added.',
        () => {
          this.action_add_edit_dialog(false, null)
        }
      )
    }
  }
}
</script>

<style></style>
