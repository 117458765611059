<template>
  <v-card v-bind="$attrs" class="rounded-10" elevation="5">
    <v-card-title class="d-flex align-center justify-space-between">
      <strong class="primary--text">{{ item.friendly_name }}</strong>
      <div>
        <HelpToolTip
          @click:icon="$emit('delete')"
          icon="mdi-delete"
          icon-color="error"
          text="Delete configuration"
        ></HelpToolTip>
        <HelpToolTip
          @click:icon="$emit('edit')"
          icon="mdi-pencil"
          text="Edit configuration"
        ></HelpToolTip>
      </div>
    </v-card-title>
    <v-card-text class="px-7">
      <v-row align="center" justify="space-between">
        <v-col cols="12" md="7" sm="12">
          <div class="mb-2">
            <div class="d-flex align-center justify-space-between">
              <label class="subtitle-2 primary--text">
                Twilio Account SID
              </label>
            </div>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.account_sid"
            ></v-text-field>
          </div>
          <div class="mb-2">
            <div class="d-flex align-center justify-space-between">
              <label class="subtitle-2 primary--text">
                Twilio Auth Token
              </label>
            </div>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.mask_token"
            ></v-text-field>
          </div>
          <div class="mb-2">
            <div class="d-flex align-center justify-space-between">
              <label class="subtitle-2 primary--text"> Twilio Number </label>
            </div>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.source_number"
            ></v-text-field>
          </div>
          <div class="mb-2">
            <div class="d-flex align-center justify-space-between">
              <label class="subtitle-2 primary--text">
                Twilio Message Service ID
              </label>
            </div>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.message_sid"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <v-img
            v-if="mdAndUp"
            :src="require('@/assets/appIcons/logo-twilio-mark red.png')"
            contain
          ></v-img>
          <div class="mb-2">
            <div class="d-flex align-center flex-column justify-center">
              <strong class="primary--text">
                Balance : <span class="fs-20">${{ item.balance }}</span>
              </strong>
              <label class="subtitle-2 primary--text">
                Twilio Number Capabilities
              </label>
            </div>
            <div class="my-1 d-flex align-center justify-center">
              <HelpToolTip
                text="Can send voice messages"
                icon="mdi-phone"
                v-if="can_voice"
                icon-color="success"
                icon-class="mr-3"
              >
              </HelpToolTip>
              <HelpToolTip
                text="Can send SMS"
                icon="mdi-message"
                v-if="can_sms"
                icon-color="success"
                icon-class="mr-3"
              >
              </HelpToolTip>
              <HelpToolTip
                text="Can send MMS"
                icon="mdi-image"
                v-if="can_mms"
                icon-color="success"
                icon-class="mr-3"
              >
              </HelpToolTip>
              <HelpToolTip
                text="Can send fax messages"
                icon="mdi-fax"
                v-if="can_fax"
                icon-color="success"
                icon-class="mr-3"
              >
              </HelpToolTip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    item: { type: Object, required: true }
  },
  computed: {
    can_voice() {
      return (
        this.item &&
        this.item.capabilities &&
        this.item.capabilities.includes('voice')
      )
    },
    can_sms() {
      return (
        this.item &&
        this.item.capabilities &&
        this.item.capabilities.includes('sms')
      )
    },
    can_mms() {
      return (
        this.item &&
        this.item.capabilities &&
        this.item.capabilities.includes('mms')
      )
    },
    can_fax() {
      return (
        this.item &&
        this.item.capabilities &&
        this.item.capabilities.includes('fax')
      )
    }
  }
}
</script>
